
<template>
    <div>
        <system-organizational-stu-structure-scroll-wrapper></system-organizational-stu-structure-scroll-wrapper>
    </div>
</template>

<script>
import SystemOrganizationalStuStructureScrollWrapper from '@/components/scrollWrapper/SystemOrganizationalStuStructureScrollWrapper'
export default {
    name:'SystemOrganizationalStuStructure',
    components:{
        SystemOrganizationalStuStructureScrollWrapper
    }
}
</script>

<style>

</style>