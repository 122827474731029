<template>
<!--  成绩等级设置-->
  <system-exam-setting-scroll-wrapper />
</template>

<script>
import SystemExamSettingScrollWrapper from "@/components/scrollWrapper/SystemExamSetting";
export default {
  name: "systemExamSetting",
  components: {
    SystemExamSettingScrollWrapper
  }
}
</script>

<style scoped>

</style>
