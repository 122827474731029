<template>
  <!--系统设置 > 系统权限-->
  <KeepAliveRouterView />
</template>

<script>
export default {
  name: "SystemPermission"
}
</script>

<style scoped>

</style>
