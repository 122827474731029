<template>
  <div>
    <Log :token="token" :projectId="projectId" :systemMask="systemMark" ref="log"></Log>
  </div>
</template>

<script>
  import axios from 'axios'
  import Vue from 'vue'

  const host = process.env.VUE_APP_XK_SERVER_LOG_AGENCY_API
  const authHost = process.env.VUE_APP_XK_SERVER_AUTH_API

  const service = axios.create({
    baseURL: host,
    withCredentials: true, // send cookies when cross-domain requests
    timeout: 50000 // request timeout
  })

  service.interceptors.request.use(
    config => {
      // if (store.getters.token) {
      //   config.headers['Authorization'] = getToken()
      // }
      return config
    },
    error => {
      console.log(error) // for debug
      return Promise.reject(error)
    }
  )

  function getLoginToken(data) {
    // return request({
    //   url: '/user/getLogLoginToken',
    //   method: 'post',
    //   data
    // })
    return Vue.prototype._fet('/school/login/getLogLoginToken', data)
  }

  function singleLogin(data) {
    return axios({
      method: 'post',
      url: authHost + '/login/singleLogin',
      data
    }).then(r => r.data.data)
  }

  // 因为项目中的axios配置了拦截器设置token头, 故不能使用项目的中的axios请求
  function getInfo(token) {
    return service({
      url: host + '/login/getUserInfo',
      method: 'get',
      headers: {
        'Authorization': token
      },
    }).then(r => r.data.data)
  }

  export function logList(data, token) {
    return service({
      url: host + '/log/listLogThird',
      method: 'post',
      data,
      headers: {
        'Authorization': token
      },
    }).then(r => r.data.data)
  }

  export function logListExport(data, token) {
    return service({
      url: host + '/log/exportLog',
      method: 'post',
      data,
      headers: {
        'Authorization': token
      },
      responseType: 'blob'
    })
  }

  export function check(value, permissions) {
    if (value && value instanceof Array && value.length > 0) {
      // const permissions = userPermission
      const permissionDatas = value

      const hasPermission = permissions.some(permission => {
        return permissionDatas.includes(permission)
      })

      if (!hasPermission) {
        return false
      }
      return true
    } else {
      console.error(`need roles! Like check="['system:user:add','system:user:edit']"`)
      return false
    }
  }

  import Log from './log/SystemLog.vue'

  export default {
    components: {
      Log
    },
    created() {
      this.toChat('log')
    },
    data() {
      return {
        token: '',
        projectId: '',
        systemMark: '',
        userPermission: []
      }
    },
    methods: {
      toChat(path) {
        const name = this.$store.state.loginName
        getLoginToken({
          username: name
        }).then(r => {
          if (r.data.code != 200) return this.$message.error(r.data.msg)
          const res = r.data.data
          this.projectId = res.projectId
          this.systemMark = res.systemMark
          this.$refs.log.getProp()
          singleLogin(res).then(r => {
            this.token = r.token
            getInfo(r.token).then(e => {
              this.userPermission = e.userPermission
              // this.$refs.log.getList()
            })
          })
        })
      }
    }
  }
</script>

<style scoped>

</style>
