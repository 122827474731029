<template>
    <div>
        <!-- 基础数据 > 组织架构 > 部门架构 -->
        <system-organizational-structure-scroll-wrapper></system-organizational-structure-scroll-wrapper>
    </div>
</template>
<script>
    import SystemOrganizationalStructureScrollWrapper from '@/components/scrollWrapper/SystemOrganizationalStructure.vue';
    export default {
        name: "SystemOrganizationalStructure",
        components: {
            SystemOrganizationalStructureScrollWrapper
        }
    }
</script>