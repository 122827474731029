<template>
  <!--系统设置>字典管理-->
  <system-dict-scroll-wrapper />
</template>

<script>
import SystemDictScrollWrapper from "@/components/scrollWrapper/SystemDict";

export default {
  name: "InterconnectionSwiper",
  components: {
    SystemDictScrollWrapper
  }
}
</script>
