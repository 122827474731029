<template>
  <!--系统设置>角色管理-->
  <system-role-scroll-wrapper />
</template>

<script>
import SystemRoleScrollWrapper from "@/components/scrollWrapper/SystemRole";

export default {
  name: "InterconnectionSwiper",
  components: {
    SystemRoleScrollWrapper
  }
}
</script>
