<template>
    <div>
        <!-- 系统管理 -->
        <KeepAliveRouterView />
    </div>
</template>
<script>
export default {
    name: "System"
}
</script>