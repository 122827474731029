<template>
  <!--系统设置>用户管理-->
  <system-personnel-scroll-wrapper />
</template>

<script>
import SystemPersonnelScrollWrapper from "@/components/scrollWrapper/SystemPersonnel";

export default {
  name: "InterconnectionSwiper",
  components: {
    SystemPersonnelScrollWrapper
  }
}
</script>
