<template>
  <!--系统设置 > 查看等级方式  -->
  <system-exam-view-scroll-wrapper />
</template>

<script>
import SystemExamViewScrollWrapper from '@/components/scrollWrapper/SystemExamView'
export default {
  name: "SystemExamView",
  components: {
    SystemExamViewScrollWrapper
  }
}
</script>

<style scoped>

</style>
